<template>
  <div>
    <v-row justify-sm="center">
      <v-card 
        class="mt-4"
        width="95%"
        fill
      >
        <v-tabs
            background-color="primary"
            grow
            dark
            v-model="tab"
          >
            <v-tab
              key="admision"
              href="#admision"
              v-if="mostrarVista(1)"
            >
              Admisión de <br> Pacientes
            </v-tab>

            <v-tab 
              v-if="mostrarVista(2)"
              key="consulta_medica"
              href="#consulta_medica"
              @click="actualizarItems('consulta_medica')"
            >
              Consulta <br> Medica
            </v-tab>

            <v-tab 
              v-if="mostrarVista(9)"
              key="consulta_dental"
              href="#consulta_dental"
              @click="actualizarItems('consulta_dental')"
            >
              Consulta <br> Dental
            </v-tab>

            <v-tab 
              v-if="mostrarVista(10)"
              key="consulta_nutricional"
              href="#consulta_nutricional"
              @click="actualizarItems('consulta_nutricional')"
            >
              Consulta <br> Nutricional
            </v-tab>

            <v-tab
              v-if="mostrarVista(13)"
              key="promociones"
              href="#promociones"
              @click="actualizarItems('promociones')"
            >
              Promoción <br> de la Salud
            </v-tab>

            <v-tab
              v-if="mostrarVista(3)"
              key="pacientes"
              href="#pacientes"
              @click="actualizarItems('pacientes')"
            >
              Pacientes
            </v-tab>

            <v-tab
              v-if="mostrarVista(11)"
              key="almacen"
              href="#almacen"
            >
              Farmacia <br> UMM
            </v-tab>

            <v-tab
              v-if="mostrarVista(7)"
              key="pagos"
              href="#pagos"
            >
              Solicitudes <br> de pago
            </v-tab>


            <v-tab
              v-if="mostrarVista(16)"
              key="incidencias"
              href="#incidencias"
            >
              Incidencias
            </v-tab>


            <v-tab
              v-if="mostrarVista(4)"
              key="catalogos"
              href="#catalogos"
            >
              Cátalogos
            </v-tab>

            <!--
            <v-tab
              key="mantenimientos"
              href="#mantenimientos"
              @click="actualizarItems('mantenimientos')"
            >
              Mantenimientos
            </v-tab>
            -->

            <v-tab
              v-if="mostrarVista(5)"
              key="roles"
              href="#roles"
              @click="actualizarItems('roles')"
            >
              Roles
            </v-tab>

            <v-tab
              v-if="mostrarVista(6)"
              key="usuarios"
              href="#usuarios"
              @click="actualizarItems('usuarios')"
            >
              Usuarios
            </v-tab>

          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item
              v-if="mostrarVista(1)"
              value="admision"
            >
              <admision-paciente></admision-paciente>
            </v-tab-item>

            <v-tab-item
              v-if="mostrarVista(2)"
              value="consulta_medica"
            >
              <consulta-medica
                ref="consulta_medica"
              >
              </consulta-medica>
            </v-tab-item>

            <v-tab-item 
              v-if="mostrarVista(9)"
              value="consulta_dental"
            >
              <consulta-dental
                ref="consulta_dental"
              >
              </consulta-dental>
            </v-tab-item>

            <v-tab-item 
              v-if="mostrarVista(10)"
              value="consulta_nutricional"
            >
              <consulta-nutricional
                ref="consulta_nutricional"
              >
              </consulta-nutricional>
            </v-tab-item>

            <v-tab-item
              v-if="mostrarVista(13)"
              value="promociones"
            >
              <promociones
                ref="promociones"
              >
              </promociones>
            </v-tab-item>

            <v-tab-item 
              v-if="mostrarVista(3)"
              value="pacientes"
            >
              <pacientes
                ref="pacientes"
              ></pacientes>
            </v-tab-item>

            <v-tab-item
              v-if="mostrarVista(11)"
              value="almacen"
            >
              <almacen
                ref="almacen"
              ></almacen>
            </v-tab-item>

            <v-tab-item
              v-if="mostrarVista(7)"
              value="pagos"
            >
              <pagos></pagos>
            </v-tab-item>

            <v-tab-item
              v-if="mostrarVista(16)"
              value="incidencias"
            >
              <incidencias></incidencias>
            </v-tab-item>

            <v-tab-item
              v-if="mostrarVista(4)"
              value="catalogos"
            >
              <catalogos></catalogos>
            </v-tab-item>

            <!--
            <v-tab-item
              value="mantenimientos"
            >
              <mantenimientos
                ref="mantenimientos"
              ></mantenimientos>
            </v-tab-item>
            -->

            <v-tab-item
              v-if="mostrarVista(5)"
              value="roles"
            >
              <roles
                ref="roles"
              ></roles>
            </v-tab-item>

            <v-tab-item
              v-if="mostrarVista(6)"
              value="usuarios"
            >
              <users
                ref="usuarios"
              ></users>
            </v-tab-item>


          </v-tabs-items>
      </v-card>
    </v-row>
    <Footer>
    </Footer>
  </div>
</template>

<script>
import Catalogos from './Catalogos.vue'
import Pacientes from './Pacientes.vue'
import Users from './Users.vue'
import Roles from './Roles.vue'
import pagos from './Pagos.vue'
import almacen from './Almacen.vue'
import AdmisionPaciente from './AdmisionPaciente.vue'
import ConsultaMedica from './ConsultaMedica.vue'
import ConsultaDental from './ConsultaDental.vue'
import ConsultaNutricional from './ConsultaNutricional.vue'
import Promociones from './Promociones.vue'
import Incidencias from './Incidencias.vue'
//import Mantenimientos from './Mantenimientos.vue'
import Footer from '@/components/DashViews/Comunes/Footer.vue'
import { mapState } from 'vuex'

export default {
  components:{
    Catalogos,
    Pacientes,
    Roles,
    Users,
    pagos,
    almacen,
    AdmisionPaciente,
    ConsultaMedica,
    ConsultaDental,
    ConsultaNutricional,
    Promociones,
    Footer,
    Incidencias,
    //Mantenimientos
  },
  computed: {
    ...mapState(['ids_vistas', 'extemporaneo'])
  },
  data: () => ({
    tab: null,
  }),
  created() {
  },
  methods: {
    //actualizarConsultaMedica(){
    //  if(this.$refs.consulta_medica !== undefined) this.$refs.consulta_medica.getConsultasMedicas();
    //},
    //actualizarPacientes(){
    //  if(this.$refs.pacientes !== undefined) this.$refs.pacientes.getItems();
    //},
    actualizarItems(refName) {
      if (this.$refs[refName] && this.$refs[refName].getItems) {
        this.$refs[refName].getItems();
      } else {
        console.warn(`No se encontró la referencia: ${refName}`);
      }
    },
    mostrarVista(id_vista){
      if(this.ids_vistas == null || this.ids_vistas.length == 0) return false;
      return (this.ids_vistas.indexOf(id_vista) >= 0);
    }
  }
}
</script>