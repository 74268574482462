<template>
    <div>
      <v-alert-dialog
        ref="alert"
      >
      </v-alert-dialog>
      <v-dialog
        v-model="dialog"
        max-width="50%"
      >
        <v-card>
          <v-card-text class="text-center">
            <v-icon
              size="300"
              color="green"
            >
              mdi-check-circle
            </v-icon>
            <div class="text-h4">
              Paciente Guardado Correctamente
            </div>
            <br>
            <v-btn
              block
              color="green"
              class="white--text"
              @click="dialog = false"
            >
              Aceptar
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogMunicipio"
        max-width="50%"
      >
        <v-card>
          <v-card-title>
            Agregar Municipio
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="municipioEditedItem.id_estado"
                  :items="estados"
                  outlined
                  label="Estado"
                  :item-text="nombreEstado"
                  item-value="id_estado"
                  :loading="loadingEstados"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="municipioEditedItem.nombre"
                  outlined
                  label="Municipio"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="green"
              class="white--text"
              @click="restablecerMunicipio"
              :loading="savingMunicipio"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="red"
              class="white--text"
              @click="guardarMunicipio"
              :loading="savingMunicipio"
            >
              Agregar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogLocalidad"
        max-width="50%"
      >
        <v-card>
          <v-card-title>
            Agregar Localidad de Atención
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="localidadEditedItem.id_estado"
                  :items="estados"
                  outlined
                  label="Estado"
                  :item-text="nombreEstado"
                  item-value="id_estado"
                  :loading="loadingEstados"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="localidadEditedItem.nombre"
                  outlined
                  label="Localidad de Atención"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="green"
              class="white--text"
              @click="restablecerLocalidad"
              :loading="savingLocalidad"

            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="red"
              class="white--text"
              @click="guardarLocalidad"
              :loading="savingLocalidad"
            >
              Agregar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row class="ma-0 pa-0">
        <v-col cols="12" class="pa-0 ma-0">
          <v-container style="max-width: 1800px !important;">
            <v-stepper v-model="tab">
              <v-stepper-header>
                <v-stepper-step
                  :complete="tab > 1"
                  step="1"
                >
                  Datos Basicos
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  :complete="tab > 2"
                  step="2"
                >
                  Buscar Paciente Subsecuente
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  :complete="tab > 3"
                  step="3"
                >
                  Completar Datos Basicos
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  :complete="tab > 4"
                  step="4"
                >
                  Tomar Fotografía
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  :complete="tab > 5"
                  step="5"
                >
                  Signos Vitales
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  :complete="tab > 6"
                  step="6"
                >
                  Servicios
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <br>
                  <v-row>
                    <v-col cols="9">


                      <v-row>
                        <v-col cols="4">
                          <v-text-field
                            v-model="editedItem.nombre"
                            label="Nombre(s)"
                            outlined
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="editedItem.apellido_paterno"
                            label="Apellido Paterno"
                            outlined
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="editedItem.apellido_materno"
                            label="Apellido Materno"
                            outlined
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-datetime-picker
                            label="Fecha de Nacimiento"
                            v-model="editedItem.fecha_nacimiento"
                          >
                          </v-datetime-picker>
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete
                            v-model="editedItem.sexo"
                            :items="generos"
                            item-value="id_genero"
                            item-text="nombre"
                            label="Sexo"
                            outlined
                            @change="cambioGenero"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete
                            v-model="editedItem.embarazada"
                            :items="respuestas"
                            item-text="text"
                            item-value="value"
                            label="Embarazada"
                            outlined
                            :readonly="editedItem.sexo != 1"
                            :disabled="editedItem.sexo != 1"
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 pa-0">
                        <v-col cols="12" class="text-center">
                          <v-btn
                            color="primary"
                            @click="buscarPacientes(false)"
                          >
                            <v-icon>
                              mdi-magnify
                            </v-icon>
                            Busqueda por Datos Personales
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-divider
                      vertical
                    >
                    </v-divider>
                    <v-col cols="3">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.folio"
                            outlined
                            label="Folio"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" class="text-center">
                          <v-btn
                            color="secondary white--text"
                            @click="buscarPacientes(true)"
                          >
                            <v-icon>
                              mdi-magnify
                            </v-icon>
                            Busqueda por Folio
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <br>
                  <v-row>
                    <v-row>
                      <v-col
                        v-if="loadingCoincidencias == true"
                        cols="12"
                        class="text-center text-h2 grey--text text--darken-2"
                      >
                        <v-progress-circular
                          :size="240"
                          :width="10"
                          color="primary"
                          indeterminate
                        ></v-progress-circular>
                        <br>
                        <br>
                        Buscando Coincidencias
                      </v-col>
                      <v-col
                        v-if="coincidencias.length == 0 && loadingCoincidencias == false"
                        cols="12"
                        class="text-center text-h2 grey--text text--darken-2"
                      >
                        <v-icon
                          :size="240"
                          color="grey--text text--darken-2"
                        >
                          mdi-account-search
                        </v-icon>
                        <br>
                        No hay Coincidencias
                      </v-col>
                      <template v-if="coincidencias.length > 0 && loadingCoincidencias == false">
                        <v-col
                          v-for="(coincidencia, idx) in coincidencias"
                          :key="'coincidencia'+idx"
                          cols="4"
                        >
                          <v-card
                            class="mx-auto my-12"
                            max-width="374"
                          >
                            <v-img
                              height="250"
                              :src="(coincidencia.fotografia != null) ? `/api/archivos/pacientes/${coincidencia.fotografia}?jwt=${token}` : '/img/persona.jpg'"
                            ></v-img>

                            <v-card-title class="pb-0 mb-0">
                              {{coincidencia.nombre}} {{coincidencia.apellido_paterno}} {{coincidencia.apellido_materno}}
                            </v-card-title>

                            <v-card-text class="py-0 my-0">
                              <div class="my-4 text-subtitle-1">
                                <b>Folio:</b> {{coincidencia.folio}}
                                <br>
                                <b>Fecha de Nacimiento:</b> {{coincidencia.fecha_nacimiento}}
                              </div>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn
                                color="primary"
                                block
                                @click="elegirPaciente(coincidencia)"
                              >
                                Elegir Paciente
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </template>
                      
                    </v-row>
                  </v-row>

                  <v-btn
                    text
                    @click="resetPaciente"
                  >
                    Anterior
                  </v-btn>

                  <v-btn
                    color="primary"
                    @click="completarDatosBasicos"
                  >
                    Omitir
                  </v-btn>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <br>
                  <v-row>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="editedItem.id_estado"
                        :items="estados"
                        outlined
                        label="Estado"
                        :item-text="nombreEstado"
                        item-value="id_estado"
                        @change="getMunicipios(); getLocalidadesAtencion();"
                        :loading="loadingEstados"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="editedItem.id_municipio"
                        :items="municipios"
                        item-text="nombre"
                        item-value="id_municipio"
                        outlined
                        label="Municipio"
                        :loading="loadingMunicipios"
                        :disabled="editedItem.id_estado == null || editedItem.id_estado <= 0 || loadingMunicipios"
                        append-outer-icon="mdi-plus"
                        @click:append-outer="addMunicipio"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="editedItem.id_localidad_atencion"
                        outlined
                        :name="uuidv4"
                        :items="localidadesAtencion"
                        :loading="loadingLocalidadesAtencion"
                        label="Localidad de Atención"
                        item-value="id_localidad_atencion"
                        item-text="nombre"
                        :disabled="editedItem.id_estado == null || editedItem.id_estado <= 0 || loadingLocalidadesAtencion"
                        append-outer-icon="mdi-plus"
                        @click:append-outer="addLocalidad"
                      >
                      </v-autocomplete>
                    </v-col>
                    <!--
                    
                    -->
                    <v-col cols="6">
                      <v-text-field
                        v-model="editedItem.curp"
                        label="CURP [Opcional]"
                        outlined
                      >
                      </v-text-field>
                    </v-col>
                    <!--
                    
                    -->
                  </v-row>

                  <v-btn
                    text
                    @click="tab = 2"
                  >
                    Anterior
                  </v-btn>

                  <v-btn
                    color="primary"
                    @click="validarDatosBasicos"
                  >
                    Siguiente
                  </v-btn>
                </v-stepper-content>

                <v-stepper-content step="4">
                  <br>
                  <v-row>
                    <v-col cols="4" />
                    <v-col cols="4">
                      <v-combobox
                        v-model="webcam"
                        label="Web Cam"
                        outlined
                        :items="devices"
                        hide-details
                        dense
                        return-object
                        @change="changeCamera"
                      >
                      </v-combobox>
                    </v-col>
                    <v-col cols="4" />
                  </v-row>
                  <v-row>
                    <v-col cols="12" style="text-align: -webkit-center;">
                      <v-card
                      max-width="800px"
                      elevation="0"
                    >
                      <v-card-text class="card-text">
                        <div>
                          <WebCam
                            v-if="tab == 4 && editedItem.fotografia_base64 == null"
                            :deviceId="deviceId"
                            ref="webcam"
                            width="600px"
                            height="100%"
                            :autoplay="false"
                            @cameras="onCameras"
                          >
                          </WebCam>
                          <v-container
                            v-else
                            class="d-flex justify-center align-center align-self-center"
                            style="text-align: -webkit-center;"
                          >
                            <v-layout>
                              <v-flex>
                                <v-img :src="editedItem.fotografia_base64" max-width="600"></v-img>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </div>
                        <div class="align-self-center">
                          <v-btn
                            v-if="editedItem.fotografia_base64 == null"
                            class="mx-2"
                            dark
                            x-large
                            color="primary"
                            @click="takePhoto"
                          >
                            <v-icon dark>
                              mdi-camera-outline
                            </v-icon>
                          </v-btn>
                          <v-btn
                            v-else
                            class="mx-2"
                            dark
                            x-large
                            color="red"
                            @click="resetPhoto"
                          >
                            <v-icon dark>
                              mdi-cached
                            </v-icon>
                          </v-btn>
                        </div>
                      </v-card-text>
                    </v-card>
                    </v-col>
                  </v-row>
                  <v-btn
                    text
                    @click="tab = 3"
                  >
                    Anterior
                  </v-btn>

                  <v-btn
                    color="primary"
                    @click="tab = 5"
                  >
                    Siguiente
                  </v-btn>
                </v-stepper-content>

                <v-stepper-content step="5">
                  <br>
                  <div class="text-subtitle-1 font-weight-bold text-center">General</div>
                  <br>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        v-model="editedItem.signos_vitales.peso"
                        outlined
                        label="Peso (Kg)"
                        @change="calculateIMC"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="editedItem.signos_vitales.estatura"
                        outlined
                        label="Estatura (metros)"
                        @change="calculateIMC"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="editedItem.signos_vitales.imc"
                        outlined
                        readonly
                        label="IMC (%)"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="editedItem.signos_vitales.cintura"
                        outlined
                        label="Cintura (cm) [Opcional]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="editedItem.signos_vitales.temperatura"
                        outlined
                        label="Temperatura (°C)"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="editedItem.signos_vitales.pulso"
                        outlined
                        label="Pulso (ppm)"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="editedItem.signos_vitales.respiracion"
                        outlined
                        label="Respiración (rpm)"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="editedItem.signos_vitales.oximetria"
                        outlined
                        label="Oximetría (% Sp02)"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="4"
                      v-if="editedItem.sexo == 2"
                    >
                      <v-text-field
                        v-model="editedItem.signos_vitales.antigeno_prostatico"
                        outlined
                        label="Antigeno Prostatico"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.signos_vitales.otros"
                        outlined
                        label="Otros"
                      >
                      </v-text-field>
                    </v-col>

                  </v-row>
                  <v-divider></v-divider>
                  <br>
                  <div class="text-subtitle-1 font-weight-bold text-center">Detección de Presión Arterial</div>
                  <br>
                  <v-row>
                    <v-col cols="6" class="pa-0 ma-0">
                      <v-row class="pa-0 ma-0">
                         <v-col cols="12">
                          <div class="text-subtitle-1 font-weight-bold text-center">Brazo Izquierdo</div>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.signos_vitales.sistolica_izquierda"
                            outlined
                            label="T.A. Sistólica izq."
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.signos_vitales.diastolostica_izquierda"
                            outlined
                            label="T.A. Diastólica"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="6" class="pa-0 ma-0">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="12">
                          <div class="text-subtitle-1 font-weight-bold text-center">Brazo Derecho</div>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.signos_vitales.sistolica_derecha"
                            outlined
                            label="T.A. Sistólica der."
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.signos_vitales.diastolostica_derecha"
                            outlined
                            label="T.A. Diastólica"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="editedItem.signos_vitales.hipertension_arterial_otros"
                        outlined
                        label="Observaciones"
                        auto-grow
                        rows="1"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <br>
                  <div class="text-subtitle-1 font-weight-bold text-center">Detecciones químicas secas</div>
                  <br>
                  <v-row>
                    <v-col cols="4" class="align-self-center">
                      <v-text-field
                        v-model="editedItem.signos_vitales.glucosa"
                        outlined
                        label="Glucosa"
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="editedItem.signos_vitales.colesterol"
                        outlined
                        label="Colesterol"
                        dense
                        hide-details
                        clearable
                        :disabled="editedItem.signos_vitales.colesterol_otro != null"
                        @change="editedItem.signos_vitales.colesterol_otro = null"
                        @click:clear="clearColesterol"
                      >
                      </v-text-field>
                      <br>
                      <v-autocomplete
                        v-model="editedItem.signos_vitales.colesterol_otro"
                        :items="fuera_rango"
                        outlined
                        label="Colesterol - Valor Fuera Rango"
                        dense
                        hide-details
                        clearable
                        :disabled="editedItem.signos_vitales.colesterol != null && editedItem.signos_vitales.colesterol.length > 0"
                        @change="editedItem.signos_vitales.colesterol = null"
                        @click:clear="clearColesterol"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="editedItem.signos_vitales.trigliceridos"
                        outlined
                        label="Triglicéridos"
                        dense
                        hide-details
                        clearable
                        :disabled="editedItem.signos_vitales.trigliceridos_otro != null"
                        @change="editedItem.signos_vitales.trigliceridos_otro = null"
                        @click:clear="clearTrigliceridos"
                      >
                      </v-text-field>
                      <br>
                      <v-autocomplete
                        v-model="editedItem.signos_vitales.trigliceridos_otro"
                        :items="fuera_rango"
                        outlined
                        label="Triglicéridos - Valor Fuera Rango"
                        dense
                        hide-details
                        clearable
                        :disabled="editedItem.signos_vitales.trigliceridos != null && editedItem.signos_vitales.trigliceridos.length > 0"
                        @change="editedItem.signos_vitales.trigliceridos = null"
                        @click:clear="clearTrigliceridos"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="editedItem.signos_vitales.quimicas_secas_observaciones"
                        outlined
                        label="Observaciones"
                        auto-grow
                        rows="1"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <v-btn
                    text
                    @click="tab = 4"
                  >
                    Anterior
                  </v-btn>

                  <v-btn
                    color="primary"
                    @click="validarSignosVitales"
                  >
                    Siguiente
                  </v-btn>
                </v-stepper-content>

                <v-stepper-content step="6">
                  <br>
                  <div class="text-subtitle-1 font-weight-bold text-center">Servicios a Realizar</div>
                  <br>
                  <v-row>
                    <v-col cols="4" class="text-center">
                      <v-container
                        @click="servicesEditedItem.medical_consultation = !servicesEditedItem.medical_consultation"
                      >
                        <v-icon
                        size="200"
                        :color="servicesEditedItem.medical_consultation ? 'teal': 'grey lighten-2'"
                        >
                          mdi-medical-bag
                        </v-icon>
                        <div :class="'text-subtitle-1 font-weight-bold '+(servicesEditedItem.medical_consultation ? 'black--text' : 'grey--text text--lighten-2')">Consulta Médica</div>
                      </v-container>
                      <v-autocomplete
                        v-model="servicesEditedItem.medical_consultation_id_nu"
                        :disabled="!servicesEditedItem.medical_consultation"
                        :readonly="!servicesEditedItem.medical_consultation"
                        outlined
                        label="Nivel de Emergencia - Consulta Médica"
                        :items="niveles_urgencias"
                        item-text="nombre"
                        item-value="id_nivel_urgencia"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="4" class="text-center">
                      <v-container
                        @click="servicesEditedItem.dental_consultation = !servicesEditedItem.dental_consultation"
                      >
                        <v-icon
                          size="200"
                          :color="servicesEditedItem.dental_consultation ? 'blue': 'grey lighten-2'"
                        >
                          mdi-tooth-outline
                        </v-icon>
                        <div :class="'text-subtitle-1 font-weight-bold '+(servicesEditedItem.dental_consultation ? 'black--text' : 'grey--text text--lighten-2')">Consulta Dental</div>
                      </v-container>
                      <v-autocomplete
                        v-model="servicesEditedItem.dental_consultation_id_nu"
                        :disabled="!servicesEditedItem.dental_consultation"
                        :readonly="!servicesEditedItem.dental_consultation"
                        outlined
                        label="Nivel de Emergencia - Consulta Dental"
                        :items="niveles_urgencias"
                        item-text="nombre"
                        item-value="id_nivel_urgencia"
                      >
                      </v-autocomplete>
                    
                    </v-col>
                    <v-col cols="4" class="text-center"
                      @click="servicesEditedItem.nutrition = !servicesEditedItem.nutrition"
                    >
                      <v-icon
                        size="200"
                        :color="servicesEditedItem.nutrition ? 'orange': 'grey lighten-2'"
                      >
                        mdi-food-apple
                      </v-icon>
                      <div :class="'text-subtitle-1 font-weight-bold '+(servicesEditedItem.nutrition ? 'black--text' : 'grey--text text--lighten-2')">Nutrición</div>
                    </v-col>
                  </v-row>
                  <v-btn
                    text
                    @click="tab = 5"
                    :disabled="saving"
                    :loading="saving"
                  >
                    Anterior
                  </v-btn>

                  <v-btn
                    color="primary"
                    @click="savePatient"
                    :disabled="saving"
                    :loading="saving"
                  >
                    Finalizar
                  </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-container>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import VDatetimePicker from '../VDatetimePicker.vue'
import VAlertDialog from '../VAlertDialog.vue'
import { WebCam } from 'vue-cam-vision'
import axios from "axios"
import { mapState } from 'vuex'
import { v4 } from 'uuid';

export default {
  components:{
    VDatetimePicker,
    VAlertDialog,
    WebCam
  },
  data: () => ({
    webcam: null,
    tab: 1,
    loadingCoincidencias: false,
    coincidencias: [],
    devices: [],
    deviceId: null,
    dialog: false,
    estados: [],
    loadingEstados: false,
    municipios: [],
    loadingMunicipios: false,
    dialogMunicipio: false,
    dialogLocalidad: false,
    defaultEditedItem: {
      id_paciente: null,
      folio: null,
      nombre: null,
      apellido_paterno: null,
      apellido_materno: null,
      fecha_nacimiento: null,
      sexo: -1,
      embarazada: null,
      id_estado: null,
      id_municipio: null,
      id_localidad_atencion: null,
      fotografia_base64: null,
      fotografia: null,
      signos_vitales: {
        peso: null,
        estatura: null,
        imc: 0,
        cintura: null,
        temperatura: null,
        pulso: null,
        respiracion: null,
        oximetria: null,
        antigeno_prostatico: null,
        otros: null,
        sistolica_izquierda: null,
        diastolostica_izquierda: null,
        sistolica_derecha: null,
        diastolostica_derecha: null,
        hipertension_arterial_otros: null,
        glucosa: null,
        colesterol: null,
        colesterol_otro: null,
        trigliceridos: null,
        trigliceridos_otro: null,
        quimicas_secas_observaciones: null
      },
      curp: null
    },
    editedItem: {
      id_paciente: null,
      folio: null,
      nombre: null,
      apellido_paterno: null,
      apellido_materno: null,
      fecha_nacimiento: null,
      sexo: -1,
      embarazada: null,
      id_estado: null,
      id_municipio: null,
      id_localidad_atencion: null,
      fotografia_base64: null,
      fotografia: null,
      signos_vitales: {
        peso: null,
        estatura: null,
        imc: 0,
        cintura: null,
        temperatura: null,
        pulso: null,
        respiracion: null,
        oximetria: null,
        antigeno_prostatico: null,
        otros: null,
        sistolica_izquierda: null,
        diastolostica_izquierda: null,
        sistolica_derecha: null,
        diastolostica_derecha: null,
        hipertension_arterial_otros: null,
        glucosa: null,
        colesterol: null,
        colesterol_otro: null,
        trigliceridos: null,
        trigliceridos_otro: null,
        quimicas_secas_observaciones: null
      },
      curp: null
    },
    defaultServicesEditedItem: {
      medical_consultation: false,
      medical_consultation_id_nu: "283af602-4dc6-42ca-91d6-f32340dc56fa",
      dental_consultation: false,
      dental_consultation_id_nu: "283af602-4dc6-42ca-91d6-f32340dc56fa",
      nutrition: false,
    },
    servicesEditedItem: {
      medical_consultation: false,
      medical_consultation_id_nu: "283af602-4dc6-42ca-91d6-f32340dc56fa",
      dental_consultation: false,
      dental_consultation_id_nu: "283af602-4dc6-42ca-91d6-f32340dc56fa",
      nutrition: false,
    },
    fuera_rango: [
      {
        text: "LOW",
        value: "Low"
      },
      {
        text: "HIGH",
        value: "High"
      },
    ],
    generos: [
      {
        id_genero: 1,
        nombre: "Femenino"
      },
      {
        id_genero: 2,
        nombre: "Masculino"
      }
    ],
    respuestas: [
      {
        value: 2,
        text: "Si"
      },
      {
        value: 1,
        text: "No"
      }
    ],
    municipioEditedItem: {
      id_estado: null,
      nombre: null
    },
    defaultMunicipioEditedItem: {
      id_estado: null,
      nombre: null
    },
    localidadEditedItem: {
      id_estado: null,
      nombre: null
    },
    defaultLocalidadEditedItem: {
      id_estado: null,
      nombre: null
    },
    localidadesAtencion:[],
    loadingLocalidadesAtencion: false,
    savingLocalidad: false,
    savingMunicipio: false,
    niveles_urgencias:[],
    loadingNivelesUrgencia: false,
    saving: false,
    epoch: 0,
  }),
  computed: {
    ...mapState(['token'])
  },
  watch: {
    tab(){
      if(this.tab != 4 && typeof this.$refs.webcam != 'undefined') this.$refs.webcam.stop();
    }
  },
  mounted() {
    this.getEstados();
    this.getNivelesUrgencia();
  },
  methods: {
    uuidv4(){
      return v4();
    },
    nombreEstado: estado => `[${estado.codigo}] - ${estado.nombre}`,
    onCameras(devices){
      this.devices = devices.map(d => {
        return {
          text: d.label,
          value: d.deviceId
        }
      });

      var camara_logitech = this.devices.find(x => x.text.includes("HD Pro Webcam"));

      if(camara_logitech != undefined && camara_logitech != null){
        this.changeCamera(camara_logitech);
        this.webcam = camara_logitech;
      }
    },
    changeCamera(cam){
      this.deviceId = cam.value;
    },
    takePhoto(){
      this.$refs.webcam.capture()
      .then(result => {
        this.$refs.webcam.stop();
        this.editedItem.fotografia_base64 = result;
      })
      .catch(error => {
        console.log(error);
      })
    },
    resetPhoto(){
      this.editedItem.fotografia_base64 = null;
      //this.$refs.webcam.start();
    },
    cambioGenero(){
      if(this.editedItem.sexo) this.editedItem.embarazada = null;
    },
    savePatient(){
      if(this.servicesEditedItem.medical_consultation == false && this.servicesEditedItem.dental_consultation == false && this.servicesEditedItem.nutrition == false){
        this.$refs.alert.setMessage("", "Debe de seleccionar al menos un Servicio");
        this.$refs.alert.open();
        return;
      }

      if(this.servicesEditedItem.medical_consultation == true && this.servicesEditedItem.medical_consultation_id_nu == null){
        this.$refs.alert.setMessage("", "Debe de indicar el Nivel de Urgencia de la Consulta Médica");
        this.$refs.alert.open();
        return;
      }

      if(this.servicesEditedItem.dental_consultation == true && this.servicesEditedItem.dental_consultation_id_nu == null){
        this.$refs.alert.setMessage("", "Debe de indicar el Nivel de Urgencia de la Consulta Dental");
        this.$refs.alert.open();
        return;
      }

      var servicios = [];

      if(this.servicesEditedItem.medical_consultation == true){
        servicios.push({
          id_servicio: '35edc2dc-6d5d-4553-857d-3287160d325f',
          id_nivel_emergencia: this.servicesEditedItem.medical_consultation_id_nu
        });
      }

      if(this.servicesEditedItem.dental_consultation == true){
        servicios.push({
          id_servicio: 'ba93d324-93b6-4fa6-bbcf-4073a16eb9fa',
          id_nivel_emergencia: this.servicesEditedItem.dental_consultation_id_nu
        });
      }

      if(this.servicesEditedItem.nutrition == true){
        servicios.push({
          id_servicio: '20d4103c-4b3c-4b36-b274-3ae3a038babf',
          id_nivel_emergencia: null
        });
      }

      var payload = JSON.parse(JSON.stringify(this.editedItem));
      payload['servicios'] = servicios;
      payload['genero'] = (this.editedItem.sexo == 2) ? true : false;
      payload['epoch'] = this.epoch;

      this.saving = true;
      axios
        .post('/Pacientes/CrearAdmisionPaciente', payload)
        .then(() => {
          this.editedItem = Object.assign({}, this.defaultEditedItem);
          this.editedItem = JSON.parse(JSON.stringify(this.defaultEditedItem));
          this.servicesEditedItem = JSON.parse(JSON.stringify(this.defaultServicesEditedItem));
          this.tab = 1;
          this.epoch = 0;
          this.dialog = true;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.saving = false;
        });
    },
    getNivelesUrgencia(){
      this.loadingNivelesUrgencia = true;
      axios
        .get('/Catalogos/ListarNivelesUrgencia')
        .then(response => {
          this.niveles_urgencias = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingNivelesUrgencia = false;
        })
    },
    getEstados(){
      this.loadingEstados = true;
      axios
        .get('/Catalogos/ListarEstados')
        .then(response => {
          this.estados = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingEstados = false;
        })
    },
    getMunicipios(){
      if(this.editedItem.id_estado == null) return;
      this.loadingMunicipios = true;
      axios
        .get(`/Catalogos/ListarMunicipios/${this.editedItem.id_estado}`)
        .then(response => {
          this.municipios = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingMunicipios = false;
        })
    },
    getLocalidadesAtencion(){
      if(this.editedItem.id_estado == null) return;
      this.loadingLocalidadesAtencion = true;
      axios
        .get(`/Catalogos/ListarLocalidadesAtencion/${this.editedItem.id_estado}`)
        .then(response => {
          this.localidadesAtencion = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingLocalidadesAtencion = false;
        })
    },
    restablecerMunicipio(){
      this.municipioEditedItem = Object.assign({}, this.defaultMunicipioEditedItem);
      this.dialogMunicipio = false;
    },
    guardarMunicipio(){
      if(this.municipioEditedItem.id_estado == null){
        this.$refs.alert.setMessage('', 'Debe de indicar un Estado');
        this.$refs.alert.open();
        return;
      }

      this.savingMunicipio = true;
      axios
      .post(`/Catalogos/CrearMunicipio`,{
        id_estado: this.municipioEditedItem.id_estado,
        nombre: this.municipioEditedItem.nombre
      })
      .then(response => {
        this.getMunicipios();
        this.editedItem.id_municipio = response.data;
        this.restablecerMunicipio();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.savingMunicipio = false;
      })
    },
    calculateIMC(){
      try {
        if(
          this.editedItem.signos_vitales.peso == null ||
          this.editedItem.signos_vitales.estatura == null ||
          this.editedItem.signos_vitales.peso <= 0 ||
          this.editedItem.signos_vitales.estatura <= 0
        ){
          this.editedItem.signos_vitales.imc = null;
          return;
        }

        var imc = (parseFloat(this.editedItem.signos_vitales.peso) / Math.pow(parseFloat(this.editedItem.signos_vitales.estatura), 2)).toFixed(2);
        if(!isFinite(imc) || isNaN(imc)) this.editedItem.signos_vitales.imc = null;
        else this.editedItem.signos_vitales.imc = imc;
      } catch (error) {
        this.editedItem.signos_vitales.imc = null;
      }
    },
    addMunicipio(){
      this.municipioEditedItem = Object.assign({}, this.defaultMunicipioEditedItem);
      this.dialogMunicipio = true;
      this.municipioEditedItem.id_estado = this.editedItem.id_estado;
    },
    addLocalidad(){
      this.localidadEditedItem = Object.assign({}, this.defaultLocalidadEditedItem);
      this.dialogLocalidad = true;
      this.localidadEditedItem.id_estado = this.editedItem.id_estado;
    },
    restablecerLocalidad(){
      this.localidadEditedItem = Object.assign({}, this.defaultLocalidadEditedItem);
      this.dialogLocalidad = false;
    },
    guardarLocalidad(){
      if(this.localidadEditedItem.id_estado == null){
        this.$refs.alert.setMessage('', 'Debe de indicar un Estado');
        this.$refs.alert.open();
        return;
      }

      this.savingLocalidad = true;

      axios
      .post(`/Catalogos/CrearLocalidadAtencion`,{
        id_estado: this.localidadEditedItem.id_estado,
        nombre: this.localidadEditedItem.nombre
      })
      .then(response => {
        this.getLocalidadesAtencion();
        this.editedItem.id_localidad_atencion = response.data;
        this.restablecerLocalidad();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.savingLocalidad = false;
      })
    },
    buscarPacientes(folio){
      axios
      .get('/Pacientes/GetEpoch')
      .then(response => {
        this.epoch = response.data
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.buscarPacientesAction(folio);
      })
    },
    buscarPacientesAction(folio){
      if(!folio){
        if(this.editedItem.nombre == null || this.editedItem.nombre.length <= 0){
          this.$refs.alert.setMessage("", "Falta Ingresar el Nombre del Paciente");
          this.$refs.alert.open();
          return;
        }

        if(this.editedItem.apellido_paterno == null || this.editedItem.apellido_paterno.length <= 0){
          this.$refs.alert.setMessage("", "Falta Ingresar el Apellido Paterno del Paciente");
          this.$refs.alert.open();
          return;
        }

        if(this.editedItem.apellido_materno == null || this.editedItem.apellido_materno.length <= 0){
          this.$refs.alert.setMessage("", "Falta Ingresar el Apellido Materno del Paciente");
          this.$refs.alert.open();
          return;
        }

        if(this.editedItem.fecha_nacimiento == null || this.editedItem.fecha_nacimiento.length <= 0){
          this.$refs.alert.setMessage("", "Falta Ingresar la Fecha de Nacimiento del Paciente");
          this.$refs.alert.open();
          return;
        }

        if(this.editedItem.sexo <= 0){
          this.$refs.alert.setMessage("", "Falta Ingresar el Sexo del Paciente");
          this.$refs.alert.open();
          return;
        }

        if(this.editedItem.sexo == 1 && this.editedItem.embarazada == null){
          this.$refs.alert.setMessage("", "Falta Ingresar si la Paciente esta Embarazada");
          this.$refs.alert.open();
          return;
        }
        
        this.loadingCoincidencias = true;
        this.coincidencias = [];
        axios
        .post('/Pacientes/BuscarPacientesSubsecuentes',{
          nombre: this.editedItem.nombre.trim(),
          apellido_paterno: this.editedItem.apellido_paterno.trim(),
          apellido_materno: this.editedItem.apellido_materno.trim(),
          fecha_nacimiento: this.editedItem.fecha_nacimiento,
          genero: (this.editedItem.sexo == 2) ? true : false
        })
        .then(response => {
          this.coincidencias = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingCoincidencias = false;
        })

        this.tab = 2;
      }
      else{
        if(this.editedItem.folio == null || this.editedItem.folio.length <= 0){
          this.$refs.alert.setMessage("", "Falta Ingresar el Folio del Paciente");
          this.$refs.alert.open();
          return;
        }

        this.loadingCoincidencias = true;
        this.coincidencias = [];
        axios
        .post('/Pacientes/BuscarPacientesSubsecuentesFolio',{
          folio: this.editedItem.folio.trim(),
        })
        .then(response => {
          this.coincidencias = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingCoincidencias = false;
        })

        this.tab = 2;
      }
    },
    validarDatosBasicos(){
      if(this.editedItem.id_estado == null){
        this.$refs.alert.setMessage("", "Falta Ingresar el Estado del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.id_municipio == null){
        this.$refs.alert.setMessage("", "Falta Ingresar el Municipio del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.id_localidad_atencion == null){
        this.$refs.alert.setMessage("", "Falta Ingresar la Localidad de Atención del Paciente");
        this.$refs.alert.open();
        return;
      }

      this.tab = 4;
    },
    isNotPositiveNumber(number){
      return !isFinite(parseFloat(number)) || isNaN(parseFloat(number)) || parseFloat(number) <= 0
    },
    validarSignosVitales(){
      if(this.editedItem.signos_vitales.peso == null || this.isNotPositiveNumber(this.editedItem.signos_vitales.peso)){
        this.$refs.alert.setMessage("", "Falta Ingresar el Peso del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.signos_vitales.estatura == null || this.isNotPositiveNumber(this.editedItem.signos_vitales.estatura)){
        this.$refs.alert.setMessage("", "Falta Ingresar la Altura del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.signos_vitales.imc == null || this.isNotPositiveNumber(this.editedItem.signos_vitales.imc)){
        this.$refs.alert.setMessage("", "Falta Ingresar el IMC del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.signos_vitales.temperatura == null || this.isNotPositiveNumber(this.editedItem.signos_vitales.temperatura)){
        this.$refs.alert.setMessage("", "Falta Ingresar la Temperatura del Paciente");
        this.$refs.alert.open();
        return;
      }

      /*
      if(this.editedItem.signos_vitales.pulso == null || this.isNotPositiveNumber(this.editedItem.signos_vitales.pulso)){
        this.$refs.alert.setMessage("", "Falta Ingresar el Pulso del Paciente");
        this.$refs.alert.open();
        return;
      }
      */

      if(this.editedItem.signos_vitales.respiracion == null || this.isNotPositiveNumber(this.editedItem.signos_vitales.respiracion)){
        this.$refs.alert.setMessage("", "Falta Ingresar la Respiración del Paciente");
        this.$refs.alert.open();
        return;
      }

      /*
      if(this.editedItem.signos_vitales.oximetria == null || this.isNotPositiveNumber(this.editedItem.signos_vitales.oximetria)){
        this.$refs.alert.setMessage("", "Falta Ingresar la Oximetría del Paciente");
        this.$refs.alert.open();
        return;
      }
      */

      if(this.editedItem.embarazada == 2 || this.editedItem.signos_vitales.sistolica_izquierda != null && !this.isNotPositiveNumber(this.editedItem.signos_vitales.sistolica_izquierda)){
        if(this.editedItem.signos_vitales.diastolostica_izquierda == null || this.isNotPositiveNumber(this.editedItem.signos_vitales.diastolostica_izquierda)){
          this.$refs.alert.setMessage("", "Falta Ingresar la T.A. Diastólica Izquierda del Paciente");
          this.$refs.alert.open();
          return;
        }
      }

      if(this.editedItem.embarazada == 2 || this.editedItem.signos_vitales.diastolostica_izquierda != null && !this.isNotPositiveNumber(this.editedItem.signos_vitales.diastolostica_izquierda)){
        if(this.editedItem.signos_vitales.sistolica_izquierda == null || this.isNotPositiveNumber(this.editedItem.signos_vitales.sistolica_izquierda)){
          this.$refs.alert.setMessage("", "Falta Ingresar la T.A. Sistólica Izquierda del Paciente");
          this.$refs.alert.open();
          return;
        }
      }

      if(this.editedItem.embarazada == 2 || this.editedItem.signos_vitales.sistolica_derecha != null && !this.isNotPositiveNumber(this.editedItem.signos_vitales.sistolica_derecha)){
        if(this.editedItem.signos_vitales.diastolostica_derecha == null || this.isNotPositiveNumber(this.editedItem.signos_vitales.diastolostica_derecha)){
          this.$refs.alert.setMessage("", "Falta Ingresar la T.A. Diastólica Derecha del Paciente");
          this.$refs.alert.open();
          return;
        }
      }

      if(this.editedItem.embarazada == 2 || this.editedItem.signos_vitales.diastolostica_derecha != null && !this.isNotPositiveNumber(this.editedItem.signos_vitales.diastolostica_derecha)){
        if(this.editedItem.signos_vitales.sistolica_derecha == null || this.isNotPositiveNumber(this.editedItem.signos_vitales.sistolica_derecha)){
          this.$refs.alert.setMessage("", "Falta Ingresar la T.A. Sistólica Derecha del Paciente");
          this.$refs.alert.open();
          return;
        }
      }

      this.tab = 6;
    },
    completarDatosBasicos(){
      //this.tab = 3;
      axios
      .get('/Configuraciones/MostrarConfiguracion?ignore_config=true')
      .then(response => {
        if(response.data == null || response.data == '') return;
        
        this.editedItem.id_estado = response.data.id_estado;
        this.getMunicipios();
        this.getLocalidadesAtencion();
        this.editedItem.id_municipio = response.data.id_municipio;
        this.editedItem.id_localidad_atencion = response.data.id_localidad_atencion; 
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.tab = 3;
      })
    },
    elegirPaciente(coincidencia){
      this.editedItem.id_paciente = coincidencia.id_paciente;
      this.editedItem.nombre = coincidencia.nombre;
      this.editedItem.apellido_paterno = coincidencia.apellido_paterno;
      this.editedItem.apellido_materno = coincidencia.apellido_materno;
      this.editedItem.fecha_nacimiento = coincidencia.fecha_nacimiento;
      this.editedItem.sexo = (coincidencia.genero == true) ? 2 : 1;
      this.editedItem.id_estado = coincidencia.id_estado;
      this.editedItem.id_municipio = coincidencia.id_municipio;
      this.editedItem.id_localidad_atencion = coincidencia.id_localidad_atencion;
      this.editedItem.signos_vitales.peso = coincidencia.peso;
      this.editedItem.signos_vitales.estatura = coincidencia.estatura;
      this.calculateIMC();
      this.getMunicipios();
      this.getLocalidadesAtencion();
      this.tab = 3;
    },
    resetPaciente(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.editedItem = JSON.parse(JSON.stringify(this.defaultEditedItem));
      this.editedItem.id_paciente = null;
      this.tab = 1;
    },
    clearTrigliceridos(){
      this.editedItem.signos_vitales.trigliceridos_otro = null;
      this.editedItem.signos_vitales.trigliceridos = null;
    },
    clearColesterol(){
      this.editedItem.signos_vitales.colesterol_otro = null;
      this.editedItem.signos_vitales.colesterol = null;
    }
  }
}
</script>

<style scoped>
.card-text {
  display: flex;
  justify-content: space-between;
}
</style>